import React from 'react'
import { Container, StyledLink } from './nav.css'

const Nav = () => (
  <Container>
    <ul>
      <li>
        <StyledLink activeClassName="active" to="/work" partiallyActive={true}>
          Work
        </StyledLink>
      </li>
      <li>
        <a href="mailto:info@miek.com.au">Email</a>
      </li>
    </ul>
  </Container>
)

export default Nav
