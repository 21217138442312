import styled from 'styled-components'
import { bodyColor } from 'constants/typography'
import { Link } from 'gatsby'

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      & + li {
        margin-left: 2rem;
      }
    }
  }
`

export const StyledLink = styled(Link)`
  &.active {
    position: relative;
    background-image: linear-gradient(to right, ${bodyColor}, ${bodyColor});
    background-position: 0 1.04em;
    background-repeat: repeat-x;
    background-size: 8px 2px;
  }
`
