import React from 'react'
import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Logo from 'images/miek_logo.svg'
import colors from 'constants/colors'

const Wrapper = styled.footer`
  border-top: 1px solid #efefef;
`

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 1rem;
  margin: 4rem auto;

  ${MEDIA.MIN_TABLET`
    
  `};
`

const Col = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  ${MEDIA.MIN_TABLET`
    width: auto;
    flex: none;
  `};
`

const StyledLogo = styled(Logo)`
  vertical-align: middle;
  width: 2.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;

  path {
    fill: ${colors.grayDark};
  }
`

const Lockup = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-top: -0.3rem;
  margin-bottom: 2rem;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 0;
  `};
`

const year = new Date().getFullYear()

const Footer = () => (
  <Wrapper>
    <Layout>
      <Col>
        <Lockup>
          <StyledLogo />
          Miek
        </Lockup>
      </Col>
      <Col>
        <p>User Experience design</p>
      </Col>
      <Col>
        <p>info@miek.com.au</p>
      </Col>
      <Col>
        <p>All contents &copy; Miek {year}</p>
      </Col>
    </Layout>
  </Wrapper>
)

export default Footer
