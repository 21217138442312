import styled from 'styled-components'
import {
  headingsColor,
  headingFontFamily,
  fontSizeLead,
  headingsFontWeight,
} from 'constants/typography'
import Logo from 'images/miek_logo.svg'
import colors from 'constants/colors'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  line-height: 1;

  a {
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`

export const Title = styled.div`
  font-family: ${headingFontFamily};
  font-size: ${fontSizeLead};
  font-weight: ${headingsFontWeight};

  a {
    color: ${headingsColor};
  }
`

export const StyledLogo = styled(Logo)`
  vertical-align: middle;
  width: 2.2rem;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;

  path {
    fill: ${colors.brandPurple};
  }
`
