import colors from './colors'

export const fontSizeBase = '1rem'
export const lineHeight = 1.5
export const spaceAfter = '1rem'
export const bodyColor = colors.grayDark

//fonts
export const fontFamilySans = `-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`
export const fontFamilyDefault = fontFamilySans
export const headingFontFamily = `'Space Text', ${fontFamilySans}`

// headings
export const headingsFontWeight = '600'
export const headingsColor = colors.black
export const fontSizePageTitle = '3rem'
export const fontSizePageTitleMobile = '2rem'
export const headingsLetterSpacing = '-0.01em'

//links
export const linkColor = colors.grayDark

//leads
export const fontSizeLead = '1.25rem'
